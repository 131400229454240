import React, { FC, useState } from 'react';
import cn from 'classnames';

import { OrderDataExtended } from 'components/types';
import UserOrdersList from 'components/UserOrdersList/UserOrdersList';
import {i18nt} from 'translations/i18n';
import Tabs from 'kit/Tabs/Tabs';
import Tab from 'kit/Tabs/Tab/Tab';

import styles from './UserOrdersContent.module.scss';

export interface UserOrdersContentProps {
  orders: {past: OrderDataExtended[], actual: OrderDataExtended[]},
}

const UserOrdersContent: FC<UserOrdersContentProps> = ({orders}) => {
    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <div className={cn('UserOrdersContent_component', styles.component)}>
            <Tabs value={selectedTab} onChange={(e, newValue) =>  setSelectedTab(newValue)} aria-label="basic tabs example">
                <Tab label={i18nt('ACTUAL')}  value={0}/>
                <Tab label={i18nt('PAST')} value={1}/>
            </Tabs>
            {selectedTab== 0 && (
              <UserOrdersList orders={orders.actual}/>
            )}
            {selectedTab== 1 && (
              <UserOrdersList orders={orders.past}/>
            )}
        </div>
    )
}

export default UserOrdersContent;
