import React, {FC, useEffect, useState} from 'react';

import { i18nt } from 'translations/i18n';
import { useAuth } from 'providers/AuthProvider';
import { useOrganization } from 'providers/OrganizationProvider';
import { apiGetOrders } from 'api/order';
import Spinner from 'components/Spinner/Spinner';
import NoData from 'components/NoData/NoData';
import UserOrdersContent, {
  UserOrdersContentProps
} from 'components/pages/UserOrdersPage/UserOrdersContent/UserOrdersContent';
import UserHelloBlock from 'components/pages/UserOrdersPage/UserHelloBlock/UserHelloBlock';
import AuthForm from 'components/AuthForm/AuthForm';
import OrderPageHeader from 'components/OrderPageHeader/OrderPageHeader';
import {dateFormatInTZ} from 'utils/dateUtil';

import {  OrderDataExtended } from '../../types';

import styles from './UserOrdersPage.module.scss';

const UserOrdersPage: FC = () => {
  const { user } = useAuth();
  const { organization, organizationId, navigateToOrganizationPage } = useOrganization();
  const [isLoadingOrders, setIsLoadingOrders] = useState<boolean>(false);
  const [orders, setOrders] = useState<OrderDataExtended[]>([]);
  const getOrders = apiGetOrders(organizationId, {sortOrder: 'asc'});

  useEffect(() => {
    if (user && organization?.id) {
      setIsLoadingOrders(true);
      getOrders
        .then((result) => setOrders(result.data))
        .finally(() => setIsLoadingOrders(false));
    }

  }, [ user, organization ]);

  //TODO: Need to move grouping orders to BE
  const categorizedOrders = orders?.reduce((acc: UserOrdersContentProps['orders'] , order) => {
    const currentDateAndTimeInTZ = new Date(dateFormatInTZ({date: new Date(), formatString: 'yyyy-MM-dd HH:mm:ss', tz: organization!.timezone}));
    const isPast = new Date(`${order.date}:${order.timeStart}`) < currentDateAndTimeInTZ;

    if(isPast) {
      return {
        ...acc,
        past: [...acc.past, order],
      }
    }
    return {
      ...acc,
      actual: [...acc.actual, order],
    }

  }, {past: [], actual: []})

  return (
    <div className={styles.component}>
      <OrderPageHeader defaultBackButton title={i18nt('MY_APPOINTMENTS')}
                       backButton={{ onClick: () => navigateToOrganizationPage() }}
      />
      <div className={styles.content}>
        {!user && (
          <AuthForm/>
        )}

        {user && (
          <>
            <UserHelloBlock/>
            {!isLoadingOrders && orders?.length === 0 && (
              <NoData title={i18nt('THERE_ARE_NO_ORDERS_YET')}/>
            )}
            {isLoadingOrders && (
              <Spinner isFullPage />
            )}

            {!isLoadingOrders && categorizedOrders && orders && orders?.length > 0 && (
              <UserOrdersContent orders={categorizedOrders}/>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default UserOrdersPage;
