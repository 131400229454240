import { ReactComponentElement } from 'react';

import { ReactComponent as addRound } from './addRound.svg';
import { ReactComponent as addSquare } from './addSquare.svg';
import { ReactComponent as calendar } from './calendar.svg';
import { ReactComponent as chartMenu } from './chartMenu.svg';
import { ReactComponent as companyInfo } from './companyInfo.svg';
import { ReactComponent as employees } from './employees.svg';
import { ReactComponent as exit } from './exit.svg';
import { ReactComponent as home } from './home.svg';
import { ReactComponent as list } from './list.svg';
import { ReactComponent as noData } from './noData.svg';
import { ReactComponent as organizations } from './organizations.svg';
import { ReactComponent as scheduling } from './scheduling.svg';
import { ReactComponent as theming } from './theming.svg';
import { ReactComponent as world } from './world.svg';
import { ReactComponent as ordersSolid } from 'assets/icons/ordersSolid.svg';
import { ReactComponent as orders } from 'assets/icons/orders.svg';
import { ReactComponent as plusCircledSolid } from 'assets/icons/plusCircledSolid.svg';
import { ReactComponent as homeSolid } from './homeSolid.svg';
import { ReactComponent as user } from './user.svg';
import { ReactComponent as settings } from './settings.svg';
import { ReactComponent as exitDoor } from './exitDoor.svg';
import { ReactComponent as chevronLeft } from './chevronLeft.svg';
import { ReactComponent as chevronRight } from './chevronRight.svg';
import { ReactComponent as mapPointOnMap } from './mapPointOnMap.svg';
import { ReactComponent as clock } from './clock.svg';
import { ReactComponent as flagEn } from 'assets/icons/flagEn.svg';
import { ReactComponent as flagFr } from 'assets/icons/flagFr.svg';
import { ReactComponent as flagUa } from 'assets/icons/flagUa.svg';
import { ReactComponent as facePensiveThin } from 'assets/icons/facePensiveThin.svg';
import { ReactComponent as googleColor } from 'assets/icons/googleColor.svg';
import { ReactComponent as device } from 'assets/icons/device.svg';
import { ReactComponent as infoRound } from 'assets/icons/infoRound.svg';

const icons = {
  noData,
  addRound,
  addSquare,
  home,
  homeSolid,
  employees,
  calendar,
  scheduling,
  list,
  chartMenu,
  exit,
  exitDoor,
  theming,
  organizations,
  companyInfo,
  world,
  orders,
  ordersSolid,
  plusCircledSolid,
  user,
  settings,
  chevronLeft,
  chevronRight,
  mapPointOnMap,
  clock,
  flagEn,
  flagFr,
  flagUa,
  facePensiveThin,
  googleColor,
  device,
  infoRound,
};

export type IconsMapType = {
  [key in keyof typeof icons]: ReactComponentElement<any>;
};

export default icons;
