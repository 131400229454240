const TEXTS = {
  REQUIRED_FIELD: 'Поле обов`язкове',
  EMAIL_INVALID: 'Невалідний email',
}

export const VALIDATION_ERRORS = {
  email_duplicated: 'Користувач з цим email вже зареєстрований',
}

export default TEXTS;
