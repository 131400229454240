import api from "api/api";
import {OrderData, OrderDataExtended, SpecialistDTO} from 'components/types';
import {ApiResponse} from "./apiTypes";

export const apiSubmitOrder = (orderData : OrderData, organizationId: number): Promise<ApiResponse<OrderDataExtended>> => api.post(`/organization/${organizationId}/orders`, orderData);
export const apiDeleteOrder = (hash : string, organizationId: number): Promise<ApiResponse<OrderDataExtended>> => api.delete(`/organization/${organizationId}/order_hash/${hash}`);


export const apiGetOrderByHash = (hash : string, organizationId: number): Promise<ApiResponse<OrderDataExtended>> => api.get(`/organization/${organizationId}/order_hash/${hash}`);

export const apiGetOrders = (organizationId: number, query?: Record<string, string>): Promise<ApiResponse<OrderDataExtended[]>> => api.get(`/organization/${organizationId}/client/orders`, query);